import { Environment, EnvironmentVariables } from '@app-environments/types'

export const environment: EnvironmentVariables = {
    productionMode: true,
    name: Environment.Test,

    api: {
        clientId: '2',
        clientSecret: '2o3sF2uoTdp2fDDV1ol3jSV54fmngtZKNBrRf8Tg',
        schema: 'https',
        hostname: 'api.joinprogram.kubernetes.pwstaging.tech',
        acceptVersionRange: '>=3.34.3 <5.0.0',
    },

    pubsub: {
        schema: 'wss',
        hostname: 'v2.pubsub.joinprogram.kubernetes.pwstaging.tech',
    },
}
