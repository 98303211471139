import { Injectable } from '@angular/core'
import { Nil } from '@app-types/common.types'
import { clone, equals, isNil, mergeRight, not, prop } from 'ramda'
import {
    BehaviorSubject,
    distinctUntilChanged,
    filter,
    firstValueFrom,
    map,
    merge,
    Observable,
    startWith,
    switchMap,
} from 'rxjs'
import { ClientFragment, ClientQueryService, LocationFragment, LocationQueryService } from '@app-graphql/api-schema'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { getChildParams } from '@app-lib/routes.lib'
import { ShortcodeInfo } from '@app-types/shortcode.types'
import { AuthService } from '@app-services/auth/auth.service'
import { shareReplayOne } from '@app-lib/rxjs.lib'

@Injectable({
    providedIn: 'root',
})
export class ShortcodeService {
    public client$: Observable<ClientFragment | Nil>
    public location$: Observable<LocationFragment | Nil>
    public shortcodeInfo$: Observable<ShortcodeInfo>

    private readonly shortcode$ = new BehaviorSubject<string | Nil>(null)

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private authService: AuthService,
        private clientQueryService: ClientQueryService,
        private locationQueryService: LocationQueryService,
    ) { }

    public async initialize(): Promise<void> {
        this.createShortcodeObservable()
        this.createClientObservable()
        this.createLocationObservable()

        this.router.events.pipe(
            filter((event): event is NavigationEnd => event instanceof NavigationEnd),
            map(() => getChildParams(this.route.snapshot.root)),
            map(prop('shortcode')),
            distinctUntilChanged<string>(equals),
        ).subscribe((shortcode) => this.shortcode$.next(shortcode))
    }

    public getShortcode(): string | null {
        return this.shortcode$.getValue() ?? null
    }


    public async isShortcodeValid(shortcode: string | Nil): Promise<boolean> {
        if (isNil(shortcode)) return false
        const currentUser = await this.authService.getCurrentUser()
        if (currentUser?.location?.shortcode === shortcode) return true

        const authorized = await this.authService.authorize(shortcode)
        if (! authorized) return false
        const newUser = await this.authService.getCurrentUser()
        return not(isNil(newUser)) && newUser?.location?.shortcode === shortcode
    }

    private createShortcodeObservable(): void {
        const defaultShortcodeInfo: ShortcodeInfo = {
            isLoading: true,
            isValid: false,
            isInvalid: true,
            shortcode: undefined,
        }

        const shortcodeInfo$: Observable<ShortcodeInfo> = this.shortcode$.pipe(
            switchMap(async (shortcode): Promise<ShortcodeInfo> => {
                if (isNil(shortcode)) {
                    return {
                        isValid: false,
                        isInvalid: true,
                        isLoading: false,
                    }
                }

                const isValid = await this.isShortcodeValid(shortcode)

                return {
                    isLoading: false,
                    isValid,
                    isInvalid: ! isValid,
                    shortcode: shortcode,
                }
            }),
            startWith(clone(defaultShortcodeInfo)),
            shareReplayOne(),
        )

        const loadingPatches: Observable<Partial<ShortcodeInfo>> = this.shortcode$.pipe(
            map((shortcode): Partial<ShortcodeInfo> => ({ isLoading: true, shortcode: shortcode ?? undefined })),
        )

        this.shortcodeInfo$ = merge(shortcodeInfo$, loadingPatches).pipe(
            map(shortcodeInfo => mergeRight(defaultShortcodeInfo, shortcodeInfo)),
            distinctUntilChanged<ShortcodeInfo>(equals),
        )
    }

    private createClientObservable(): void {
        this.client$ = this.authService.getUserObservable().pipe(
            switchMap(async ({ user }) => {
                if (isNil(user)) return null
                return this.fetchClient(user.client.id)
            }),
            distinctUntilChanged<ClientFragment | Nil>(equals),
            shareReplayOne(),
        )
    }

    private createLocationObservable(): void {
        this.location$ = this.authService.getUserObservable().pipe(
            switchMap(async ({ user }) => {
                if (isNil(user)) return null
                return this.fetchLocation(user.location?.id, user.location?.shortcode)
            }),
            distinctUntilChanged<LocationFragment | Nil>(equals),
            shareReplayOne(),
        )
    }

    private async fetchClient(clientId?: string | Nil): Promise<ClientFragment | Nil> {
        if (isNil(clientId)) {
            return null
        }

        const result = await firstValueFrom(this.clientQueryService.fetch({ id: clientId }))
        return result.data.client
    }

    private async fetchLocation(locationId?: string | Nil, shortcode?: string | Nil): Promise<LocationFragment | null> {
        const result = await firstValueFrom(this.locationQueryService.fetch({ id: locationId, shortcode }))
        return result.data.location ?? null
    }
}
